<template>
    <div :class="'type-' + data.type ">
        <div class="treeviewdiv">
            <div :class="'nodetext w-100 type-' + data.type ">
                <div class="d-inline-block tree-column mr-3">{{ data.name }} </div>
                <div v-if="data.type !== '20'" class="d-inline-block  mr-3 tree-column" :title="data.type">{{ types[data.type-1].label }}</div>
                <div v-if="data.type !== '20'" class="d-inline-block  tree-column">{{data.slug}}</div>
            </div>

     </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                data: {
                },
                types: [
                    {"value" : '1' ,"label" : 'Text'},
                    {"value" : '2' ,"label" : 'Výberovník'},
                    {"value" : '3' ,"label" : 'Obrázok'},
                    {"value" : '4' ,"label" : 'Dlhý text'},
                    {"value" : '5' ,"label" : 'Celé číslo'},
                    {"value" : '6' ,"label" : 'Desatiné číslo'},
                    {"value" : '7' ,"label" : 'Logická hodnota Áno/Nie'},
                    {"value" : '8' ,"label" : 'Priradenie pod typ obsahu'},
                    {"value" : '9' ,"label" : 'Dynamická hodnota'},
                    {"value" : '10' ,"label" : 'Builder'},
                    {"value" : '11' ,"label" : 'Galéria obrázkov'},
                    {"value" : '12' ,"label" : 'Priradenie pod taxonómiu'},
                    {"value" : '13' ,"label" : 'Priradenie pod taxonómiu s násobkom'},
                    {"value" : '14' ,"label" : 'Dátum'},
                    {"value" : '15' ,"label" : 'Multipole - vlastnosti'},
                    {"value" : '16' ,"label" : 'Dátum od - do'},
                    {"value" : '17' ,"label" : 'Multipole'},
                    {"value" : '18' ,"label" : 'Ikona'},
                    {"value" : '19' ,"label" : 'Súbor'},
                    {"value" : '20' ,"label" : 'Skupina'},
                    {"value" : '21' ,"label" : 'Mapa'},
                    {"value" : '22' ,"label" : 'Priradenie konkrétneho obsahu'},
                ],
            };
        },
        methods: {
            onClickButton (data) {
       this.$emit('open', data)
            }
        }
    };

</script>
